import { FC, memo } from 'react';

// Styles
import { TextLink } from 'src/common/TextLink';
import { VacancyItem as StyledVacancyItem } from '../VacancyCountBlock.styles';

// Types
import { VacancyListItemType } from '../VacancyCountBlockTypes';

const VacancyItem: FC<VacancyListItemType> = ({ title, count, href }) => {
  return (
    <StyledVacancyItem>
      <TextLink href={href} size="l">
        <h4>{title}</h4>
      </TextLink>
      <span>{count} vacatures</span>
    </StyledVacancyItem>
  );
};

export default memo(VacancyItem);
