import slugify from 'slugify';

const slugifyOptions = {
  lower: true,
  strict: true,
  trim: true,
};

export const cities = [
  'Alkmaar',
  'Almere',
  'Amersfoort',
  'Amsterdam',
  'Apeldoorn',
  'Arnhem',
  'Badhoevedorp',
  'Breda',
  'Breukelen',
  'Delft',
  'Den Bosch',
  'Den Haag',
  'Den Helder',
  'Deventer',
  'Diemen',
  'Doetinchem',
  'Dordrecht',
  'Driebergen',
  'Dronten',
  'Ede',
  'Eemshaven',
  'Eindhoven',
  'Elst',
  'Emmen',
  'Enschede',
  'Gorinchem',
  'Gouda',
  'Groningen',
  'Haarlem',
  'Heerlen',
  'Helmond',
  'Hengelo',
  'Hilversum',
  'Leeuwarden',
  'Leiden',
  'Lelystad',
  'Leusden',
  'Maastricht',
  'Meppel',
  'Middelburg',
  'Nieuwegein',
  'Nijmegen',
  'Nunspeet',
  'Roosendaal',
  'Rotterdam',
  'Sittard',
  'Terschelling',
  'Thorn',
  'Tilburg',
  'Utrecht',
  'Veghel',
  'Velp',
  'Velsen',
  'Venlo',
  'Vlissingen',
  'Wageningen',
  'Woensdrecht',
  'Zaltbommel',
  'Zierikzee',
  'Zoetermeer',
  'Zwolle',
];

export const citiesSlugMap = cities.reduce((acc, city) => {
  const slug = slugify(city, slugifyOptions);

  acc[slug] = {
    slug,
    title: city,
    searchValue: city,
  };

  return acc;
}, {} as Record<string, { slug: string; title: string; searchValue: string }>);

export const popularCities = [
  'Amsterdam',
  'Rotterdam',
  'Utrecht',
  'Groningen',
  'Nijmegen',
  'Eindhoven',
  'Tilburg',
  'Enschede',
  'Leiden',
  'Delft',
  'Maastricht',
  'Den Haag',
  'Wageningen',
  'Leeuwarden',
  'Almere',
  'Den Bosch',
  'Breda',
  'Arnhem',
  'Deventer',
  'Zwolle',
  'Haarlem',
  'Amersfoort',
  'Heerlen',
  'Middelburg',
  'Alkmaar',
];

export const popularCitiesSlugMap = popularCities.reduce((acc, popularCity) => {
  const slug = slugify(popularCity, slugifyOptions);

  acc[slug] = {
    slug,
    title: popularCity,
    searchValue: popularCity,
  };

  return acc;
}, {} as Record<string, { slug: string; title: string; searchValue: string }>);

export const jobFunctions = [
  { title: 'Zorg', searchValue: 'Zorg' },
  { title: 'Callcenter', searchValue: 'Callcenter' },
  { title: 'Bijles', searchValue: 'Bijles' },
  { title: 'Chauffeur', searchValue: 'Chauffeur' },
  { title: 'Schoonmaak', searchValue: 'Schoonmaak' },
  { title: 'Logistiek', searchValue: 'Logistiek' },
  { title: 'Administratieve', searchValue: 'Administrate' },
  { title: 'Bezorger', searchValue: 'Bezorger' },
  { title: 'ICT', searchValue: 'ICT' },
  { title: 'Horeca', searchValue: 'Hotel Restaurant Cafe' },
];

export const jobFunctionsSlugMap = jobFunctions.reduce((acc, jobFunction) => {
  const slug = slugify(jobFunction.title, slugifyOptions);

  acc[slug] = {
    slug,
    title: jobFunction.title,
    searchValue: jobFunction.searchValue,
  };

  return acc;
}, {} as Record<string, { slug: string; title: string; searchValue: string }>);
