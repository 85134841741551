import { FC, memo } from 'react';

// Types
import { VacancyListItemType } from './VacancyCountBlockTypes';

// Styles
import {
  BackgroundImage,
  Container,
  H3,
  StyledContentWrapper,
  VacancyList,
  Wrapper,
} from './VacancyCountBlock.styles';
import VacancyItem from './components/VacancyItem';

export type VacancyCountBlockProps = {
  title?: string;
  items?: VacancyListItemType[];
};

const VacancyCountBlock: FC<VacancyCountBlockProps> = ({ title, items }) => {
  return (
    <Container>
      <StyledContentWrapper>
        <Wrapper>
          <H3>{title}</H3>
          <VacancyList>
            {items?.map((el) => (
              <VacancyItem key={el.title} title={el.title} count={el.count} href={el.href} />
            ))}
          </VacancyList>
        </Wrapper>
        <BackgroundImage />
      </StyledContentWrapper>
    </Container>
  );
};

export default memo(VacancyCountBlock);
