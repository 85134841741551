import { jobFunctionsSlugMap, popularCitiesSlugMap } from 'constants/slug-maps';

export const getJobFunctionsCountersData = (
  facets?: Record<string, number>
): Array<{ title: string; count: number; href: string; searchValue: string }> => {
  const _facets = facets || {};

  return Object.values(jobFunctionsSlugMap).map(({ title, searchValue, slug: href }) => {
    let count = 0;

    if (searchValue.toLowerCase() in _facets) {
      count = _facets[searchValue.toLowerCase()];
    }

    return { title, count, href, searchValue };
  });
};

export const getCitiesCountersData = (
  facets?: Record<string, number>
): Array<{ title: string; count: number; href: string; searchValue: string }> => {
  const _facets = facets || {};

  return Object.values(popularCitiesSlugMap).map(({ title, searchValue, slug: href }) => {
    let count = 0;

    if (searchValue.toLowerCase() in _facets) {
      count = _facets[searchValue.toLowerCase()];
    }

    return { title, count, href, searchValue };
  });
};
